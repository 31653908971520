import Web3 from "web3";
import ethers from "ethers";
import react,{useState} from 'react';
const ERROR_CODE_TX_REJECTED_BY_USER = 4001;



export const _getReward = async (
  contract,
  accounts,
  selectedAddress,
  setRewardBalance
) => {
  console.log("getreward");
  await contract.methods
    .updateRewardFunc()
    .send({ from: accounts[0], gas: 500000 });
  _updateReward(contract, selectedAddress, setRewardBalance);
};

export const _getVested = async (
  contract,
  accounts,
  selectedAddress,
  setVestedBalance
) => {
  await contract.methods
    .updateUnlockedNew()
    .send({ from: accounts[0], gas: 500000 });
  _updateVested(contract, selectedAddress, setVestedBalance);
};

export const _updateReward = async (
  contract,
  selectedAddress,
  setRewardBalance
) => {
  try {
    const rewardBalanceRes = await contract?.methods
      .rewards(selectedAddress)
      .call();
    const rewardBalance = Web3.utils.fromWei(String(rewardBalanceRes), "ether");
    setRewardBalance(rewardBalance);
    console.log(">>>wws", rewardBalance);
  } catch (err) {
    console.log(">>m _updateReward", err);
  }
};

export const _updateVested = async (
  contract,
  selectedAddress,
  setVestedBalance
) => {
  try {
    const vestedBalanceRes = await contract?.methods
      .vestedTokens(selectedAddress)
      .call();
    const vestedBalance = Web3.utils.fromWei(String(vestedBalanceRes), "ether");
    setVestedBalance(vestedBalance);
    console.log(">>>wps", vestedBalance);
  } catch (err) {
    console.log(">>m _updateVested", err);
  }
};
export const _claimReward = async (
  selectedAddress,
  contract,
  setRewardBalance
) => {
  const rewardTransfer = await contract.methods
    .getReward()
    .send({ from: selectedAddress, gas: 500000 });
  console.log("rewards", rewardTransfer);
  _updateReward(contract, selectedAddress, setRewardBalance);
};

export const _withDraw = async (
  amount,
  selectedAddress,
  contract
) => {
  try {
    console.log("withdarw");
    const withDrawTokens = Web3.utils.toWei(amount, "ether");

    // _dismissTransactionError(setTransactionError);
    const tx = await contract.methods
      .withdraw(withDrawTokens)
      .send({ from: selectedAddress, gas: 500000 });
    //await _updateStakeBalance(contract, selectedAddress);
  } catch (error) {
    if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
      return;
    }
    console.error(error);
  } finally {
  }
};
export const _stake = async (amount, selectedAddress, contract) => {
  try {
    console.log("_stake");
    const stakeTokens = Web3.utils.toWei(amount, "ether");

    const tx = await contract.methods
      .stake()
      .send({ value: stakeTokens, from: selectedAddress, gas: 500000 });
    //await _updateStakeBalance(contract, selectedAddress);
  } catch (error) {
    if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
      return;
    }
    console.error(error);
  } finally {
  }
};

export const _updateStakeBalance = async (contract, selectedAddress,setStakedBalance,setTotalStakedTokens) => {
  try {
    const totalStakedTokensRes = await contract?.methods
      .totalStakedTokens()
      .call();
    console.log(">>>>>>>>>>>.", totalStakedTokensRes);
    const totalStakedTokens = Web3.utils.fromWei(
      String(totalStakedTokensRes),
      "ether"
    );

    const stakedBalanceRes = await contract?.methods
      .stakedBalance(selectedAddress)
      .call();
    const stakedBalance = Web3.utils.fromWei(String(stakedBalanceRes), "ether");
    setStakedBalance(stakedBalance);
    setTotalStakedTokens(totalStakedTokens);
    console.log(">>>ds", stakedBalance, totalStakedTokens);
  } catch (err) {
    console.log(">>m", err);
  }
};
