import React, { useState } from "react";
import "../styles.css";
import "./StakingForm.css";

import { Button, Label, Form } from "reactstrap";

export function StakingForm({
  stakeTokens,
  withDraw,
  tokenSymbol,
  handleChange,
  isToggled,
}) {
  const [submitClass, setSubmitClass] = useState("btn btn-primary submit1");
  const [buttonText, setButtonText] = useState("Stake");
  const handleClick = async (btntxt) => {
    console.log("handleclick");
    if (btntxt === "Stake") {
      setSubmitClass("btn btn-primary submit1");
      setButtonText(btntxt);
    }
    if (btntxt === "Withdraw") {
      setSubmitClass("btn btn-primary submit2");
      setButtonText(btntxt);
    }
  };

  return (
    <div>
      <div>
        <div className="radio-block">
          <input
            type="radio"
            name="contrasts"
            id="contrasts-on"
            defaultChecked
          />
          <label
            for="contrasts-on"
            className="label"
            onClick={() => handleClick("Stake")}
          >
            Stake
          </label>
          <input type="radio" name="contrasts" id="contrasts-off" />
          <label
            for="contrasts-off"
            className="off-label"
            onClick={() => handleClick("Withdraw")}
          >
            Withdraw
          </label>
          <span className="selected" aria-hidden="true"></span>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Form
        onSubmit={(event) => {
          // This function just calls the transferTokens callback with the
          // form's data.
          event.preventDefault();
          const typeSubmit = event.nativeEvent.submitter.className;
          console.log(">>>>", typeSubmit);
          const formData = new FormData(event.target);
          const amount = formData.get("amount");

          if (amount) {
            let i = typeSubmit.includes("1") ? 1 : 2;
            switch (i) {
              case 1:
                stakeTokens(amount);
                break;
              case 2:
                withDraw(amount);
                break;
              default:
                stakeTokens(amount);
            }
          }
        }}
      >
        <div className="form-row bg-transparent">
          <div className="input-data form-group">
            <input
              className=" bg-transparent"
              type="number"
              step="0.00000001"
              name="amount"
              placeholder="Enter Value"
              required
            />
            <Label className="bcx">BCX</Label>
          </div>
        </div>

        <div className="form-group ">
          <br />
          <br />
          <br />

          <Button md={3} id="stakeBtn" className={submitClass}>
            {buttonText}
          </Button>
          <img src="/info.png" className="info" alt="info.png"></img>
          <p className="inf">info</p>
        </div>
      </Form>
    </div>
  );
}
