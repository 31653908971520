import React, { useEffect, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavbarToggler,
  Collapse,
  NavItem,
  Row,
  Container,
  Col,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import Web3 from "web3";
import { useEth } from "../contexts/EthContext";
import "../styles.css";
import "./HeaderComponent.css";

export const Header = () => {
  const {
    state: { web3, selectedAddress }, tryInit,
  } = useEth();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [balance, setBalance] = useState(false);

  function toggleNav() {
    setIsNavOpen(!isNavOpen);
  }
  function down() {
    window.open("https://metamask.io/", "_blank", "noreferrer");
  }

  useEffect(() => {
    const getBalance = async (account) => {
      if (web3) {
        let bal = await web3.eth.getBalance(selectedAddress);
        let ethBal = Web3.utils.fromWei(bal, "ether");
        setBalance(ethBal);
      }
    };
    if (selectedAddress) {
      getBalance(selectedAddress);
    }
  }, [selectedAddress, web3]);

  if (!web3) {
    return (    
    <React.Fragment>
      <Navbar dark expand="md" className="navb">
        <div className="container justify-center">
          <NavbarBrand className="mr-auto">
            {" "}
            <img
              src="/LOGO.png"
              xs={4}
              alt="logo"
              
              className="logo "
            ></img>{" "}
          </NavbarBrand>
          <Collapse isOpen={isNavOpen} navbar>
            <Nav navbar className="m-auto">
            </Nav>
          </Collapse>
        </div>
        {(window.ethereum ? <Button className="connectbtn" onClick={tryInit}>Connect Wallet</Button>: <Button className="connectbtn" onClick={down}>Get a Wallet <img src="/metamask-img.webp" width={30}></img></Button>)}
      </Navbar>
    </React.Fragment>);
  }
  return (
    <React.Fragment>
      <Navbar dark expand="md" className="navb">
        <div className="container justify-center">
          {/* <NavbarToggler onClick={toggleNav} /> */}
          <NavbarBrand className="mr-auto">
            {" "}
            <img
              src="/LOGO.png"
              xs={4}
              alt="logo"
              
              className="logo "
            ></img>{" "}
          </NavbarBrand>
          <Collapse isOpen={isNavOpen} navbar>
            <Nav navbar className="m-auto">
            </Nav>
          </Collapse>
        </div>

        <ButtonDropdown className="dropdown">
          <DropdownToggle className="dropdown-header">
            <div className="dropdown-selected">
              <span className="circle"></span>
              {selectedAddress.slice(0, 7)}
            </div>
            <div className="dropdown-icon">&#x25BC;</div>
          </DropdownToggle>
        </ButtonDropdown>
      </Navbar>
    </React.Fragment>
  );
};
