import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const SimpleInterestChart = ({ principal, rate, timePeriod }) => {
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
  ];

  const calculateSimpleInterest = (principal, rate, time) => {
    return principal * ((rate / 100) * (time / 12));
  };

  const startMonthIndex = 7; // July (0-based index)
  const startYear = 24;

  // Generate labels with month names starting from July 2024
  const labels = Array.from({ length: timePeriod + 1 }, (_, i) => {
    const totalMonths = startMonthIndex + i;
    const monthIndex = totalMonths % 12;
    const year = startYear + Math.floor(totalMonths / 12);
    return `${monthNames[monthIndex]} ${year}`;
  });

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: labels.map((_, t) => calculateSimpleInterest(principal, rate, t)),
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'none',
      },
     
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Months',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Rewards',
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};


