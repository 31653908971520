import React, { useEffect, useState } from "react";
//import "../styles.css";
import { Header } from "./HeaderComponent";
import { Home } from "./HomeComponent";
import { Routes, Route, Navigate } from "react-router-dom";
import Footer from "./FooterComponent";
import { useEth } from "../contexts/EthContext";
import Web3 from "web3";

const ERROR_CODE_TX_REJECTED_BY_USER = 4001;

export const Main = () => {
  const {
    state: { artifact, web3, accounts, networkID, contract, selectedAddress },
  } = useEth();
  const [contractData, setContractData] = useState(null);
  const [stakedBalance, setStakedBalance] = useState(null);
  const [totalStakedTokens, setTotalStakedTokens] = useState(null);
  const [rewardBalance, setRewardBalance] = useState(null);
  const [vestedBalance, setVestedBalance] = useState(null);
  const [txBeingSent, setTransactionError] = useState(null);

  useEffect(() => {
    async function _updateStakeBalance() {
      try {
        const totalStakedTokensRes = await contract?.methods
          .totalStakedTokens()
          .call();
        console.log(">>>>>>>>>>>.", totalStakedTokensRes);
        const totalStakedTokens = Web3.utils.fromWei(
          String(totalStakedTokensRes),
          "ether"
        );

        const stakedBalanceRes = await contract?.methods
          .stakedBalanceAcc(selectedAddress)
          .call();
        const stakedBalance = Web3.utils.fromWei(
          String(stakedBalanceRes),
          "ether"
        );
        setStakedBalance(stakedBalance);
        setTotalStakedTokens(totalStakedTokens);
      } catch (err) { }
    }

    async function _updateReward() {
      try {
        const rewardBalanceRes = await contract?.methods
          .rewards(selectedAddress)
          .call();
        const rewardBalance = Web3.utils.fromWei(
          String(rewardBalanceRes),
          "ether"
        );
        setRewardBalance(rewardBalance);
      } catch (err) { }
    }

    async function _updateVested() {
      try {
        const transList = await contract?.methods
          .getTransListByAddr(selectedAddress)
          .call();
        let allTrans = [];
        for (let i = 0; i < transList.length; i++) {
          var tran = await contract.methods
            .allTrans(transList[i])
            .call();
          allTrans.push(tran);
        }
        let vestedBal = 0;
        for (let i = 0; i < allTrans.length; i++) {
          const vestedBalanceIn = Web3.utils.fromWei(
            String( allTrans[i].vestedTokens),
            "ether"
          );
          vestedBal += Number(vestedBalanceIn);
        }
        console.log(">>>transactions",allTrans,transList,vestedBal);
        setVestedBalance(vestedBal);
      } catch (err) { }
    }

    const interval1 = setInterval(() => _updateStakeBalance(), 3000);
    const interval2 = setInterval(() => _updateReward(), 3000);
    const interval3 = setInterval(() => _updateVested(), 3000);

    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
      clearInterval(interval3);
    };
  }, [accounts, contract, selectedAddress]);

  useEffect(() => {
    console.log("Time start person get", Date.now());

    const fetchData = async () => {
      const data = await _getContractData();
      setContractData(data);
    };

    async function _getContractData() {
      try {
        console.log("_getContractData");
        const rewardRateRes = await contract?.methods.REWARD_RATE().call();
        const rewardRate = await rewardRateRes;
        const totalStakedTokensRes = await contract?.methods
          .totalStakedTokens()
          .call();
        console.log(">>>>>.", totalStakedTokensRes);
        const totalStakedTokens = Web3.utils.fromWei(
          String(totalStakedTokensRes),
          "ether"
        );

        const stakedBalanceRes = await contract?.methods
          .stakedBalanceAcc(selectedAddress)
          .call();
        const stakedBalance = Web3.utils.fromWei(
          String(stakedBalanceRes),
          "ether"
        );
        const conData = {
          rewardRate: rewardRate,
          totalStakedTokens: totalStakedTokens,
          stakedBalance: stakedBalance,
        };
        return conData;
      } catch (err) {
        console.log(" _getContractData", err);
        return null;
      }
    }
    fetchData();
    console.log("Time end person get", contract, web3, contractData);
  }, [contract, accounts, web3, networkID]);

  useEffect(() => {
    if (contractData) {
      console.log("contr", contractData);
    }
  }, [contractData]);

  return (
    <div>
      <Header />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Home
              stakedBalance={stakedBalance}
              vestedBalance={vestedBalance}
              txBeingSent={txBeingSent}
              setTransactionError={setTransactionError}
              rewardBalance={rewardBalance}
              setRewardBalance={setRewardBalance}
              contractData={contractData}
              setVestedBalance={setVestedBalance}
            />
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};
